export default {
  items: [
    {
      name: 'Home',
      url: '/home',
      icon: 'icon-home',
      badge: {
        variant: 'primary'
      }
    },
    {
      title: true,
      name: 'Indicadores',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Short Loop',
      url: '/indicadores/short-loop',
      icon: 'icon-chart'
    },
    {
      name: 'Medium Loop',
      url: '/indicadores/medium-loop',
      icon: 'icon-chart'
    },
    {
      name: 'Long Loop',
      url: '/indicadores/long-loop',
      icon: 'icon-chart'
    }
  ]
}
